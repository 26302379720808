@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@font-face {
  font-family: 'Futura';
  src: url('../fonts/Futura Std Book.otf') format('truetype')
}

@-webkit-viewport   { width: device-width; }
@-moz-viewport      { width: device-width; }
@-ms-viewport       { width: device-width; }
@-o-viewport        { width: device-width; }
@viewport           { width: device-width; }

html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
  background-color: #77bec0;
  font-family: 'Open Sans', sans-serif;
}

body > .container-fluid {
  padding: 90px 15px 0;
  background-color: #f9b224;
}

body > .container {
  padding: 90px 15px 0;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #646e7a;
}

.container .text-muted {
  margin: 20px 0;
  color: #ffffff;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

.navbar {
  background-color: #646e7a;
  border: none;
  height: 70px;
}

.navbar-default .navbar-nav > li > a {
  color: #fff;
  padding-top: 40px;
}

.navbar-default .navbar-nav > li > a:hover {
  color: #eb5a49;
}

.navbar-brand a {
  vertical-align: middle;
  color: #ffffff;
  font-family: 'Futura';
  font-size: 1.5em;
}

.navbar-brand a:hover {
  text-decoration: none;
}

a.take-part {
  font-size: 4em;
  background-color: #eb5a49;
  padding: 10px 50px;
  border-radius: 10px;
  color: #ffffff;
}

a.take-part:hover {
  text-decoration: none;
  background-color: #f44a37;
}

.thumbnail.head-image {
  background: none;
  border: none;
}

.caption h4 {
  color: #ffffff;
}

h1.call-to-action {
  margin: 0;
  font-size: 4em;
  color: #ffffff;
  font-weight: bold;
}

.lead.call-to-action {
  font-size: 3em;
  color: #ffffff;
}

.spacer-5 {
  margin: 5em 0;
}

.spacer-top-2 {
  margin: 2em 0;
}

.logo {
  height: 50px;
}

a.more-info img {
  outline: 0;
}

a.more-info:hover img {
  opacity: 0.5;
}

.btn.btn-primary {
  border-color: #eb5a49;
  background-color: #eb5a49;
}

.btn.btn-primary:active {
  background-color: #f44a37;
  border-color: #666666;
}

div.page-header h1 {
  color: white;
}